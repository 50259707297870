import React from "react"
import RecordItem from "./RecordItem"

class RecordBlock extends React.Component {

  render() {

    return (

      <div>

        <h2 className={`uppercase font-bold text-xs xs:text-base lg:text-xl tracking-wide leading-snug text-${this.props.player} mb-4 sm:mb-6 lg:mb-4 text-center bg-noir-lighten5 border-${this.props.player} border py-3 md:py-5`}>
          <span className={`block text-xs font-light opacity-50`}>
            {this.props.orderBy === "type" ? `${this.props.player.charAt(0).toUpperCase() + this.props.player.slice(1)} ` : `${this.props.player.charAt(0).toUpperCase() + this.props.player.slice(1)} Records` }
          </span> {this.props.orderBy === "type" ? `${this.props.section}s` : `${this.props.section}`}
        </h2>

        <ul className={`flex flex-wrap mb-10 xl:mb-16`}>
          {this.props.data.filter(d => this.props.orderBy === "type" ? d.node.type === this.props.section : d.node.comp === this.props.section).map(d => (
            <RecordItem
              key={d.node.id}
              type={d.node.type}
              recordText={d.node.recordText}
              stat={d.node.stat}
              player={this.props.player}
              autoCheck={d.node.autoCheck}
              updated={d.node.updated}
              updatedAuto={d.node.updatedAuto}
              hide={d.node.hide}
            />
          ))}
        </ul>

      </div>

    )
  }
}

export default RecordBlock