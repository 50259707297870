import React from "react"
import styles from "./RecordItem.module.css"

//import Icon from "../Utilities/Icon"


class RecordItem extends React.Component {

  render() {

    const recordText = (start, bold, end, stat) => {
      return <span>{start} <strong className={`text-${this.props.player} font-semibold`}>{bold.replace('[stat]', stat)}</strong> {end}</span>;
    }

    const twoWeeksAgo = new Date(Date.now() - 691200000).getTime();
    const updated = new Date(this.props.updated).getTime()

    const datestamp = this.props.autoCheck === "auto" ? this.props.updatedAuto : this.props.updated

    return (

      this.props.hide !== 'y' ? (

        <li className={`${styles.recordItem} w-full relative flex flex-col md:flex-row mb-3 lg:mb-4 bg-noir-lighten5 ${updated > twoWeeksAgo ? 'order-first' : ''}`}>

          <p className={`${styles.recordTypeWrap} relative md:w-1/5 flex md:flex-col text-center`}>
            {updated > twoWeeksAgo ? (
              <span className={`${styles.new} uppercase text-xs bg-highlight p-1 text-noir flex items-center justify-center md:absolute`}>New!</span>
            ) : ''}
            <span className={`${styles.recordType} bg-${this.props.player} text-white p-1 px-2 md:px-1 flex items-center justify-center uppercase tracking-wide md:flex-1`}>{this.props.type}</span>
          </p>

          <p className={`relative flex-1 md:self-center p-3 pb-4 sm:p-4 sm:pb-5 lg:p-6 text-gray-400 text-sm lg:text-base lg:leading-normal`}>
            {this.props.recordText.includes('[b]') ?
              recordText(
                this.props.recordText.slice(0, this.props.recordText.indexOf("[b]")),
                this.props.recordText.substring(this.props.recordText.lastIndexOf("[b]") + 3, this.props.recordText.lastIndexOf("[/b]")),
                this.props.recordText.split('[/b]')[1],
                this.props.stat
              ) : this.props.recordText
            }
          </p>

          <p className={`w-full md:w-1/5 lg:w-1/6 py-1 px-2 text-center leading-snug flex items-center justify-center bg-noir-lighten10 text-xs md:text-sm text-gray-600`}>
            <span className={`${styles.updated} flex md:flex-col items-center tracking-wider uppercase`}>
              <span className={`mr-1 md:mr-0 md:mb-1`}>{this.props.autoCheck === "auto" ? <span class="md:block">auto</span> : ''} updated</span>
              {this.props.autoCheck !== "auto" ? (
                <span>{new Date(datestamp.replace(/-/g,"/")).toLocaleDateString("en-US", { day: 'numeric', month: 'short', year: '2-digit'})}</span>
              ) : ''}
            </span>
          </p>

        </li>

      ) : ''

    )
  }
}

export default RecordItem