import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import RecordBlock from "../components/Records/RecordBlock"

import LastUpdated from "../components/LastUpdated/LastUpdated"


class RecordsPage extends React.Component {

  state = {
    orderBy: "type"
  };

  manageOrder = (order) => {
    this.setState({ orderBy: order });
  };

  render() {

    // If state.orderBy is set to "type" get the different record types, e.g. World Record, European Record etc
    // If state.orderBy is set to "comp" get the different competition types, e.g. League, Champions League, World Cup etc.
    const messiSections = this.state.orderBy === "type" ? [...new Set(this.props.data.allSheetMessiRecords.edges.map(d => d.node.type))] : [...new Set(this.props.data.allSheetMessiRecords.edges.map(d => d.node.comp))]

    const ronaldoSections = this.state.orderBy === "type" ? [...new Set(this.props.data.allSheetRonaldoRecords.edges.map(d => d.node.type))] : [...new Set(this.props.data.allSheetRonaldoRecords.edges.map(d => d.node.comp))]


    // OrderBy Tabs
    const orderByTabs = [
      { 'shortName': 'type', 'longName': 'Record Type' },
      { 'shortName': 'comp', 'longName': 'Competition Type' }
    ]

    return (

      <Layout>
        <SEO
          title={"Records - Messi vs Ronaldo World Records, Goal Records and More"}
          description={`Messi and Ronaldo seem to break records every week, but whose are more impressive? Compare their World Records, Club Records and everything in between, with our extensive, always up-to-date list of records.`}
          canonicalPath={`/records/`}
        />

        <h1>Records <span className="sr-only">- Messi vs Ronaldo World Records and Goal Records</span></h1>

        

        <div className={`text-center w-full max-w-sm mx-auto mt-6 lg:mt-8 xl:mt-10 py-4 sm:pt-6`}>
          <p className={`text-xs text-gray-500`}>Order by:</p>
          <ul className={`flex justify-center mt-4 lg:mt-6`}>
            {orderByTabs.map(d => (
              <li key={d.shortName}>
                <button
                  onClick={() => this.manageOrder(d.shortName)}
                  onKeyDown={() => this.manageOrder(d.shortName)}
                  className={`px-4 pt-1 pb-2 mx-2 text-sm rounded-full cursor-pointer transition-300 focus:outline-none ${this.state.orderBy === d.shortName ? 'bg-gray-800 text-gray-400' : 'bg-noir-lighten5 text-gray-600 hover:bg-noir-lighten10 focus:bg-noir-lighten10'}`}
                >
                  {d.longName}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-wrap max-w-xl mx-auto justify-center px-2 mt-4 lg:mt-8">

          <article className={`w-1/2 pr-2 sm:pr-4 lg:pr-8`}>
            {messiSections.map(section => (
              <RecordBlock
                key={section}
                section={section}
                player="messi"
                data={this.props.data.allSheetMessiRecords.edges}
                orderBy={this.state.orderBy}
              />
            ))}
          </article>

          <article className={`w-1/2 pl-2 sm:pl-4 lg:pl-8`}>
            {ronaldoSections.map(section => (
              <RecordBlock
                key={section}
                section={section}
                player="ronaldo"
                data={this.props.data.allSheetRonaldoRecords.edges}
                orderBy={this.state.orderBy}
              />
            ))}
          </article>

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiRecords {
      edges {
        node {
          id
          type
          recordText
          stat
          updated
          autoCheck
          updatedAuto
          comp
          hide
        }
      }
    }
    allSheetRonaldoRecords {
      edges {
        node {
          id
          type
          recordText
          stat
          updated
          autoCheck
          updatedAuto
          comp
          hide
        }
      }
    }
  }
`

export default RecordsPage
